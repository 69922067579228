import * as React from "react"
import { primaryDark, white } from "../constants/colors"
import styled from "styled-components"
import { Icon } from "semantic-ui-react"
import { FC } from "react"
import { screenSizes } from "model"

const Button = styled.button<{ backgroundColor?: string; fontColor?: string }>`
  border-radius: 50%;
  padding: 0 0 4px 4px;
  width: 60px;
  height: 60px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ backgroundColor = white }) => backgroundColor};
  transition: opacity 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px, rgba(0, 0, 0, 0.2) 0px 2px 12px;
  transition: transform 100ms ease-in-out 0s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px, rgba(0, 0, 0, 0.2) 0px 4px 16px;
    transform: scale(1.1);
  }
  &:active {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px, rgba(0, 0, 0, 0.1) 0px 2px 8px;
    transform: scale(0.9);
  }
  @media (max-width: ${screenSizes.smallMobile}px) {
    right: 6px;
  }
  opacity: ${({ showButton }) => (showButton ? 1 : 0)};
  i {
    color: ${({ fontColor = primaryDark }) => fontColor};
  }
`

interface Props {
  show: boolean
  onClick: () => void
  iconName: string
  backgroundColor?: string
  fontColor?: string
}

const RoundIconButton: FC<Props> = ({ show, onClick, iconName, backgroundColor, fontColor }) => {
  return (
    <Button
      showButton={show}
      onClick={onClick}
      backgroundColor={backgroundColor}
      fontColor={fontColor}
    >
      <Icon name={iconName} size={"large"} />
    </Button>
  )
}

export default RoundIconButton
