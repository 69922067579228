import * as React from "react"
import styled from "styled-components"
import { colors } from "model"
import { gillFont } from "../../../../../../../constants/fonts"

const Container = styled.div`
  width: 100%;
  margin-bottom: 20px;
`

const Label = styled.label`
  display: block;
  font-family: ${gillFont};
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: bold;
  margin-bottom: 5px;
`

const Select = styled.select`
  width: 100%;
  background: white;
  border-radius: 5px;
  border: 1px solid ${({ valid }) => (valid ? colors.otherBorderGrey : colors.rouge)};
`

const Option = styled.option`
  background: white;
  color: ${colors.lightBlack};
  font-family: ${gillFont};
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 52px;
  ${({ isSelected }) => isSelected && `color: ${colors.skyBlue};`}
  :hover {
    background: ${colors.skyBlue};
    color: ${colors.white};
  }
`

const handleChange = (state, onChange) => event => {
  onChange(event.target.name, event.target.value)
}

const hearAboutUsAttributeOptions = [
  "Google",
  "Facebook",
  "Instagram",
  "LinkedIn",
  "Other social media",
  "Word of mouth",
  "Referred by a friend of colleague",
  "Blog post",
  "Press or newspapers",
]

const HearAboutUsField = ({ state, onChange, validate }) => {
  const isValid = validate ? HearAboutUsField.isValid(state) : true
  return (
    <Container>
      <Label htmlFor="hearAboutUs">How did you hear about us?</Label>
      <Select
        onChange={handleChange(state, onChange)}
        value={state.hearAboutUs}
        name="hearAboutUs"
        valid={isValid}
      >
        <Option value="" disabled selected>
          Choose an option..
        </Option>
        {hearAboutUsAttributeOptions.map(option => (
          <Option
            isSelected={option.toLowerCase() === state.hearAboutUs}
            value={option.toLowerCase()}
          >
            {option}
          </Option>
        ))}
      </Select>
    </Container>
  )
}

HearAboutUsField.isValid = state => {
  return Boolean(state.hearAboutUs)
}

export default HearAboutUsField
